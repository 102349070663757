@font-face {
  font-family: DmSansRegular;
  src: url("../../../assets/fonts/DMSans_18pt-Regular.ttf");
}
@font-face {
  font-family: DmSansBold;
  src: url("../../../assets/fonts/DMSans_18pt-Bold.ttf");
}

.signup-container {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffff;
}

.signup-wrapper {
  padding-left: 10px;
  padding-right: 10px;
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.signup-logo {
  width: 200px;
  height: 30px;
  margin-bottom: 20px;
}

.signup-wrapper .form-container {
  width: 700px;
  border: 1px solid;

  border-image-source: linear-gradient(
    251.37deg,
    #9747ff -1.39%,
    rgba(255, 255, 255, 0) 46.98%,
    #f8567a 104.14%
  );
  border-radius: 24px;
  box-shadow: 0px 4px 30px 0px #00000040;
  padding: 32px;
  z-index: 9999;
  background: rgba(233, 232, 232, 0.199);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}

.signup-header {
  display: flex;
  align-items: center;
  align-content: center;
  margin-bottom: 10px;
}

.shield-wrapper {
  width: 32px;
  height: 32px;
  border-radius: 4px;
  border: 1px solid #ffffff33;
  box-shadow: 0px 4px 30px 0px #00000040;
  display: flex;
  justify-content: center;
  align-items: center;
}

.shield {
  width: 24px;
  height: 24px;
}

.signup-header p {
  font-size: 24px;
  font-weight: 700;
  margin-left: 8px;
  color: #5f6388;
  margin-bottom: 0 !important;
  font-family: DmSansBold;
}

.inputRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.inputBox-wrapper {
  width: 48%;
  margin-top: 12px;
}

.input-label {
  font-family: DmSansBold;
  font-size: 16px;
  font-weight: 700;
  line-height: 20.83px;
  color: #5f6388;
  margin-bottom: 6px;
}

.inputBox {
  height: 48px;
  font-family: DmSansRegular;
  text-indent: 8px;
  border: 1px solid #ffffff33 !important;
  border-radius: 4px !important;
  box-shadow: 0px 4px 30px 0px #00000040 !important;
  outline: none !important;
  width: 100%;
}

.inputBox:focus {
  border: 1px solid #ffffff33 !important;
  border-radius: 4px !important;
  box-shadow: 0px 4px 30px 0px #00000040 !important;
}

.signup-btn {
  height: 44px;
  border: 1px solid #ffffff33 !important;
  border-radius: 4px;
  background: linear-gradient(98.28deg, #9747ff -5.64%, #f8567a 119.66%),
    linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2));
  color: #ffffff;
  font-size: 20px;
  font-family: DmSansBold;
  font-weight: 700;
  margin-top: 20px;
  width: 40%;
}

.signup-para {
  font-family: DmSansRegular;
  font-weight: 700;
  font-size: 14px;
  margin-bottom: 0 !important;
  color: #5f6388;
}

.signup-footer {
  font-family: DmSansRegular;
  font-size: 16px;
  margin-top: 12px;
  color: #5f6388;
  text-align: center;
  margin-bottom: 0 !important;
}

.signup-footer span {
  font-weight: 700;
  color: #9747ff;
  cursor: pointer;
}

.signup-footer span:hover {
  text-decoration: underline;
}

.icon-wrapper {
  position: relative;
}

.eye-icon {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.showEye {
  font-weight: bolder;
  color: #5f6388;
  margin-right: -21px;
  margin-top: -4px;
  font-size: 25px;
  z-index: 1000;
}

.right-pattern2,
.left-pattern2,
.center-pattern-signup,
.center-pattern2-signup {
  position: absolute;
}

.right-pattern2 {
  bottom: 0;
  right: 0;
}

.left-pattern2 {
  top: 0px;
  left: 0;
}

.center-pattern-signup {
  top: 10%;
  right: 35%;
}
.center-pattern2-signup {
  bottom: 10%;
  left: 23%;
  transform: rotate(180deg);
}

@media only screen and (max-width: 1250px) {
  .signup-wrapper .form-container {
    width: 650px;
  }
}

@media only screen and (max-width: 1100px) {
  .signup-wrapper .form-container {
    width: 100%;
  }
}

@media only screen and (max-width: 640px) {
  .signup-wrapper {
    width: 100%;
  }

  .inputRow {
    display: flex;
    flex-direction: column;
  }

  .inputBox-wrapper {
    width: 100%;
    margin-top: 20px;
  }
}

@media only screen and (max-width: 800px) {
  .left-pattern2,
  .right-pattern2,
  .center-pattern-signup,
  .center-pattern2-signup {
    display: none;
  }
}

.login-wrapper {
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.custom-select option {
  padding: 0.5rem;
  background-color: #ffffff;
  color: #333333;
}

.custom-select option:hover {
  background-color: #f3f3f0;
}

.custom-select option:checked {
  background-color: #e2e2e0;
}

/* styles.css */
