.form-desc {
  margin: 0 !important;
  font-size: 16px;
  color: #5f6388;
  margin-top: -4px !important;
}

.verification-para {
  color: #5f6388;
  font-size: 16px;
}

.verification-para span {
  font-weight: bold !important;
}

.form-btn {
  margin-top: 5px !important;
}

.resend-link {
  color: #5f6388;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  font-family: DmSansBold;
  margin-top: 10px;
  cursor: pointer;
}

.resend-link:hover {
  text-decoration: underline;
}

.center-pattern-verification,
.center-pattern2-verification {
  position: absolute;
}

.center-pattern-verification {
  top: 22%;
  right: 33.5%;
}
.center-pattern2-verification {
  bottom: 8%;
  left: 18%;
  transform: rotate(180deg);
}

@media only screen and (max-width: 800px) {
  .center-pattern-verification,
  .center-pattern2-verification {
    display: none;
  }
}
