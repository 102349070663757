.cardSection {
  border: 1px solid #edecec !important;
  border-radius: 10px;
  margin-bottom: 15px;
  background: #fff;
}

.box input:checked {
  background: yellow;
}

.submit-btn {
  width: 140px;
  height: 44px;
  border: 1px solid #ffffff33 !important;
  border-radius: 100px;
  /* background: linear-gradient(98.28deg, #9747ff -5.64%, #f8567a 119.66%),
      linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)); */
  color: #ffffff;
  font-size: 18px;
  font-family: DmSansBold;
  font-weight: 600;
  margin-top: 12px;
}

th,
tr,
td {
  font-weight: 400 !important;
}
