.message-content {
  min-height: calc(100vh - 14.7rem);
  max-height: calc(100vh - 14.7rem);
}
.bg-red {
  background-color: #fe2c55;
}
.bg-green {
  background-color: #25d366;
}
.selected {
  background-color: white;
}
