/* .addprofile-main {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
} */
.addprofile-header p {
  text-align: center;
  color: #515e5f;
}
.addprofile-header span {
  color: #a66dfe;
}
.kep-login-facebook.metro {
  border-radius: 5px;
  font-size: 12px;
}
.addprofile-mains {
  width: 50%;
  margin: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  /* transform: translate(-10%, -50%); */
}
.addprofile-inner p {
  color: #697279;
}
.ap-card {
  display: flex;
  margin: 10px;
  width: 280px;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}
.card-inner {
  display: flex;
  justify-content: center;
  padding: 36px;
}
.linkedin-btn {
  border: none;
  background-color: #a66dfe;
}
