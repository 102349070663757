@font-face {
  font-family: DmSansRegular;
  src: url("../../../assets/fonts/DMSans_18pt-Regular.ttf");
}
@font-face {
  font-family: DmSansBold;
  src: url("../../../assets/fonts/DMSans_18pt-Bold.ttf");
}

p {
  margin-bottom: 0 !important;
}

.login-container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: fffff;
}

.login-wrapper {
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.login-logo {
  width: 200px;
  /* height: 30px; */
  margin-bottom: 20px;
}
.login-input {
  height: 38px;
  width: 100%;
  border-radius: 100px;
  padding-left: 12px;
  padding-right: 12px;
  background-color: #f3f3f0;
  font-size: 14px;
}

.form-container {
  width: 100%;
  border: 1px solid;

  border-image-source: linear-gradient(
    251.37deg,
    #9747ff -1.39%,
    rgba(255, 255, 255, 0) 46.98%,
    #f8567a 104.14%
  );
  border-radius: 24px;
  box-shadow: 0px 4px 30px 0px #00000040;
  padding: 32px;
  z-index: 9999;
  background: rgba(233, 232, 232, 0.199);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}

.login-form {
  width: 70%;
}

.login-header {
  display: flex;
  align-items: center;
  align-content: center;
}

.shield-wrapper {
  width: 32px;
  height: 32px;
  border-radius: 4px;
  border: 1px solid #ffffff33;
  box-shadow: 0px 4px 30px 0px #00000040;
  display: flex;
  justify-content: center;
  align-items: center;
}

.shield {
  width: 24px;
  height: 24px;
}

.login-header p {
  font-size: 24px;
  font-weight: 700;
  margin-left: 8px;
  color: #5f6388;
  margin-bottom: 0 !important;
  font-family: DmSansBold;
}

.input-label {
  font-family: DmSansBold;
  font-size: 16px;
  font-weight: 700;
  line-height: 20.83px;
  color: #5f6388;
  margin-bottom: 6px;
}

.inputBox {
  height: 48px;
  font-family: DmSansRegular;
  text-indent: 8px;
  border: 1px solid #ffffff33 !important;
  border-radius: 4px !important;
  box-shadow: 0px 4px 30px 0px #00000040 !important;
  outline: none !important;
  width: 100%;
  background: rgba(238, 236, 236, 0.199);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  /* background-color: #f0f2fe; */
}

.inputBox:focus {
  border: 1px solid #ffffff33 !important;
  border-radius: 4px !important;
  box-shadow: 0px 4px 30px 0px #00000040 !important;
}

.forgot-pass {
  display: flex;
  justify-content: end;
}

.forgot-pass span {
  font-family: DmSansBold;
  font-size: 16px;
  font-weight: 700;
  color: #5f6388;
  cursor: pointer;
}

.login-btn {
  width: 140px;
  height: 44px;
  border: 1px solid #ffffff33 !important;
  border-radius: 100px;
  /* background: linear-gradient(98.28deg, #9747ff -5.64%, #f8567a 119.66%),
    linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)); */
  color: #ffffff;
  font-size: 18px;
  font-family: DmSansBold;
  font-weight: 600;
  margin-top: 12px;
}

.vector-image {
  position: absolute;
  bottom: -50px;
  height: 450px;
  right: -80px;
  z-index: 9999;
}

.para {
  font-family: DmSansRegular;
  font-weight: 700;
  font-size: 14px;
  margin-top: 20px;
  text-align: center;
  margin-bottom: 0 !important;
  color: #5f6388;
}

.register-here {
  font-size: 16px;
  font-family: DmSansRegular;
  font-weight: 700;
  text-align: center;
  color: #9747ff;
  cursor: pointer;
}

.register-here:hover {
  text-decoration: underline;
}

.icon-wrapper {
  position: relative;
}

.eye-icon {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.showEye {
  font-weight: bolder;
  color: #5f6388;
  margin-right: -21px;
  margin-top: -4px;
  font-size: 25px;
  z-index: 1000;
}

.right-pattern,
.left-pattern,
.center-pattern2,
.center-pattern {
  position: absolute;
}

.right-pattern {
  top: 10px;
  right: 0;
  height: 370px;
}

.left-pattern {
  bottom: 10px;
  left: 0;
  height: 430px;
  width: 420px;
}

.center-pattern2 {
  top: 25%;
  right: 35%;
}
.center-pattern {
  bottom: 10%;
  left: 40%;
}

@media only screen and (max-width: 1200px) {
  .login-wrapper {
    width: 50%;
  }
}
@media only screen and (max-width: 1000px) {
  .login-wrapper {
    width: 80%;
  }
}
@media only screen and (max-width: 800px) {
  .login-wrapper {
    width: 80%;
  }
  .vector-image {
    display: none;
  }
  .login-form {
    width: 100%;
  }
  .left-pattern,
  .right-pattern,
  .center-pattern,
  .center-pattern2 {
    display: none;
  }
}
