.item {
  margin: auto;
  box-shadow: 0px 0px 32px 0px rgba(0, 0, 0, 25%);
  border-radius: 0.5rem;
  padding: 1rem;
  /* max-width: 24rem; */
  max-width: 100%;
  transition: 0.25s ease-in-out;
}
.item:hover {
  box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 40%);
  cursor: pointer;
}

.back-btn {
  width: max-content;
  cursor: pointer;
}
.active {
  transition: 0.25s ease-in-out;
  background: linear-gradient(
    30deg,
    rgba(151, 71, 255, 30%),
    rgba(248, 86, 122, 30%)
  );
}
.button {
  transition: 0.25s ease-in-out;
  color: white;
  border: 0;
  border-radius: 4px;
  background: linear-gradient(
    30deg,
    rgba(151, 71, 255, 100%),
    rgba(248, 86, 122, 100%)
  );
  display: flex;
  justify-content: end;
}
