.App {
  text-align: center;
}

.App-logo {
  height: 100vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.pay-btn {
  /* width: 140px; */
  /* height: 44px; */
  /* border: 1px solid #ffffff33 !important; */
  /* border-radius: 4px; */
  background: linear-gradient(98.28deg, #9747ff -5.64%, #f8567a 119.66%),
    linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2));
  /* color: #ffffff; */
  /* font-size: 20px; */
  /* font-family: DmSansBold; */
  /* font-weight: 700; */
  /* margin-top: 20px; */
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Tailwind CSS */
/* 
.h-16 {
  height: 16px;
}
.h-24 {
  height: 24px;
}
.h-48 {
  height: 48px;
}
.h-64 {
  height: 64px;
}
.w-16 {
  width: 16px;
}
.w-24 {
  width: 24px;
}
.w-48 {
  width: 48px;
}
.w-64 {
  width: 64px;
} */
.flex {
  display: flex;
}
.flex-row {
  flex-direction: row;
}
.flex-col {
  flex-direction: column;
}
.flex-wrap {
  flex-wrap: wrap;
}
.justify-between {
  justify-content: space-between;
}

.justify-centre {
  justify-content: center;
}

.items-center {
  align-items: center;
}

.border-1 {
  border: 1px solid gainsboro;
}
.uppercase {
  text-transform: uppercase;
}

.w-1\/2 {
  width: 49%;
}
.text-sm {
  font-size: 0.75rem;
}

.screen-h {
  height: calc(100vh - 4rem);
}
.side-h {
  height: calc(100vh - 12rem);
}

.left-nav .active {
  background-color: #efa945;
  color: white;
}
.bottom-nav {
  width: calc(100vw - 348px);
}

.bot-nav {
  width: calc(100vw - 232px);
  position: fixed;
  bottom: -96px; /* Start it below the visible area */
  left: 0;
  right: 0;
  height: 96px; /* Set your desired height */
  color: #fff; /* Set your desired text color */
  display: flex;
  align-items: center;
  justify-content: center;
  transition: bottom 0.3s ease; /* Transition duration and easing */
  z-index: 51;
}

.bot-nav.show {
  bottom: 0; /* Bring it to the visible area */
}
.bottom-nav a {
  padding: 0;
}
.bottom-nav .active {
  background: transparent;
  color: #9747ff;
}
/* Remove blinking cursor in any text */

.no-cursor {
  user-select: none;
}
.no-cursor::selection {
  background-color: transparent; /* Set the background color to transparent */
  color: inherit; /* Inherit the text color */
  caret-color: transparent; /* Set the caret color to transparent */
}
.mid-content {
  height: calc(100vh - 3.7rem);
}

.tooltip-inner {
  max-width: 500px !important;
}

/* Assuming you add this CSS in your main stylesheet */
.react-date-picker__wrapper {
  border: none !important;
  box-shadow: none !important;
}
