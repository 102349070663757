.nav-main {
  margin: 0rem 1.5rem 0rem 1.5rem;
}
.nav-logo {
  font-family: "Gugi", cursive;
  font-weight: 700;
  font-size: 30px;
}
.nav-tab {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #231f20;
  padding: 0px 40px 0px 40px;
}
.nav-main button {
  /* background: linear-gradient(120deg, #9747ff 0%, #f8567a 140%); */
  border-radius: 20px;
  border: none;
  color: white;
  padding: 8px 30px 8px 30px;
  font-family: "DM Sans";
  font-weight: 600;
  text-transform: uppercase;
}
.nav-main a {
  text-decoration: none;
  font-family: "DM Sans";
  font-weight: 600;
  padding-right: 2rem;
  text-transform: uppercase;
}
@media (max-width: 600px) {
  .nav-main {
    margin: 0.5rem 1rem 0.5rem 1rem;
  }
}
